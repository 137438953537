import { clearActivities, FhirPatientIconsService, FhirPatientService, loadActivities, loadPractitioners, selectActivitiesExtraByShift, selectActivitiesPlannedByShift, selectActivityAll, selectedShift } from '@medlogic/fhir';
import { selectedActivityModeTitle, setActivityMode } from '@medlogic/fhir';
import { selectedActivityMode } from '@medlogic/fhir';
import { EnActivityMode, EnShift, IActivity, IShift, IActionEmitter, UnsubscribeOnDestroyAdapter, AppLogService, IPatient, GlobalService, IEvolucaoFono } from '@medlogic/shared/shared-interfaces';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { IAppMedlogicFhirState } from '@medlogic/fhir';
import { select, Store } from '@ngrx/store';
import { catchError, filter, map, mergeMap, Observable, of, switchMap, tap, toArray, zip} from 'rxjs';
import { selectedPatientId } from '@medlogic/medlogic/medlogic-state';
import { AvaliacaoNutricionalCustomService, EvolucaoEnfermagemCustomService, EvolucaoFisioterapiaCustomService, EvolucaoFonoCustomService, PatientCustomService } from '@medlogic/medlogic/medlogic-data-access';
import { CadTenantService, ModelComponent } from '@medlogic/shared/shared-data-access';

@Component({
  selector: 'ml-ui-care-plan',
  templateUrl: './ui-care-plan.component.html',
  styleUrls: ['./ui-care-plan.component.css']
})
export class UiCarePlanComponent extends UnsubscribeOnDestroyAdapter implements OnInit {

  @Output() menuItemClick = new EventEmitter<string>();
  @Output() activityClick = new EventEmitter<IActivity>();
  @Output() actionClick = new EventEmitter<IActionEmitter>();

  activityMode$: Observable<EnActivityMode> = this.store.pipe(select(selectedActivityMode));
  activityModeTitle$: Observable<string> = this.store.pipe(select(selectedActivityModeTitle));

  activitiesByShift$: Observable<IShift[]> = this.store.pipe(select(selectActivitiesPlannedByShift));
  activitiesExtraByShift$: Observable<IShift[]> = this.store.pipe(select(selectActivitiesExtraByShift));

  selectedShift$: Observable<EnShift> = this.store.pipe(select(selectedShift));

  patientIcons$ = new Observable<any>();

  selectedPatientId$: Observable<number> = this.store.pipe(select(selectedPatientId));

  ENACTIVITYMODE = EnActivityMode;

  tenantPatientCode: any = false;

  patientId: string;

  constructor(
    private log: AppLogService,
    private store: Store<IAppMedlogicFhirState>,
    private patientCustomSrv: PatientCustomService,
    private evolucaoEnfCustomSrv: EvolucaoEnfermagemCustomService,
    private avaliacaoNutriCustomSrv: AvaliacaoNutricionalCustomService,
    private evolucaoFonoCustomSrv: EvolucaoFonoCustomService,
    private evolucaoFisioCustomSrv: EvolucaoFisioterapiaCustomService,
    private cadTenantCnf: CadTenantService,
    private modelComponent: ModelComponent,
    private glb: GlobalService,
  ) {
    super();
  }

  ngOnInit() {
    try {
      this.store.dispatch(loadActivities());
      this.store.dispatch(loadPractitioners());
      this.loadDadosGeraisIcons();
    } catch (error: any) {
      this.log.Registrar(this.constructor.name, 'ngOnInit', error.message);
    }
  }

  onMenuItemClick(option: string): void {
    try {
      this.store.dispatch(loadActivities());
      switch (option?.toLowerCase() || '') {
        case EnActivityMode.CarePlan:
          this.store.dispatch(setActivityMode({ selectedActivityMode: EnActivityMode.CarePlan }));
          break;
        case EnActivityMode.Extra:
          this.store.dispatch(setActivityMode({ selectedActivityMode: EnActivityMode.Extra }));
          break;
        default:
          break;
      }
      this.menuItemClick.emit(option);
    } catch (error: any) {
      this.log.Registrar(this.constructor.name, 'onMenuItemClick', error.message);
    }
  }

  onActivityClick(activity: IActivity): void {
    try {
      console.log("emitted activity: ", activity);
      this.activityClick.emit(activity);
    } catch (error: any) {
      this.log.Registrar(this.constructor.name, 'onActivityClick', error.message);
    }
  }

  onActionClick({ actionType, data }: IActionEmitter): void {
    try {
      this.actionClick.emit({ actionType, data });
    } catch (error: any) {
      this.log.Registrar(this.constructor.name, 'onActionClick', error.message);
    }
  }

  getIconsFromPatient(patient: IPatient): any {
    const result = [];
    if (patient.obito) result.push({ display: "falecimento", description: "Óbito" });
    if (patient.lilas) result.push({ display: "plano-lilas", description: "Plano Lilás" });
    if (patient.situacaoPaciente) result.push({ display: "residente", description: "Residente Ativo" })
    return [result[0]];
  }

  getIconsFromNutri(data): any {
    const result = [];
    if (data.diabetico) result.push({ display: "diabetico", description: "Diabético" });
    if (data.usoDietaEnteral) result.push({ display: "dieta-enteral", description: "Dieta Enteral" });
    if (data.dietaDupla) result.push({ display: "dieta-dupla", description: "Dieta Mista"});
    return result;
  }

  getIconsFromFono(data: IEvolucaoFono): any {
    const result = [];
    if (data.houveMudancadietaTablet) result.push({ display: "mudanca-dieta", description: "Mudança de Dieta" });
    const consistency = data?.consistencia ? `<li>CONSISTÊNCIA: ${data.consistencia} </li>` : ""
    const type = data?.tipo2 ? "<li>" + `TIPO: ${data.tipo2}` + "</li>" : ""
    const description = '<p style="padding: 10px">Alimentação</p><br><ul>' + consistency + type + "</ul>";

    // logica para mostrar apenas um icone de refeição, se não encaixa
    let display = null;
    if (!data.suspenderDietaTablet && !data.refeicaoComAdaptacaoTablet) {
      display = "refeicao";
    } else if (data.refeicaoComAdaptacaoTablet) {
      display = "refeicao-adaptada";
    }
    if (display !== null) {
      const finalMealObject = { display, description }
    result.push(finalMealObject);
    }

    if (data.suspenderDietaTablet) result.push({ display: "dieta-suspensa", description: "Dieta Suspensa" });
    if (data.usoespessanteTablet) {
      result.push({ display: "espessante", description: "Uso de Espessante" });
      switch (data.tipoConsistenciaTablet) {
        case 'Mel':
          result.push({ display: "espessante-mel", description: "Espessante Mel" });
          break;
        case 'Pudim':
          result.push({ display: "espessante-pudim", description: "Espessante Pudim" });
          break;
        case 'Néctar':
          result.push({ display: "espessante-nectar", description: "Espessante Néctar" });
          break;
        default:
          break;
      }
    }
    return result;
  }


  getIconsFromEnf(data: any): any {
    const result = [];
    if (data.riscoFuga) result.push({ display: "risco-fuga", description: "Risco de Fuga" });
    if (data.altoRiscoQueda) result.push({ display: "risco-queda", description: "Risco de Queda" });
    if (data.tipoIsolamento) {
      switch (data.tipoIsolamento) {
        case 'Isolamento de Contato':
          result.push({ display:  "isolamento-contato", description: data.tipoIsolamento });
          break;
        case 'Isolamento de Contato e Aerosol':
          result.push({ display: "isolamento-contato-aereo", description: data.tipoIsolamento });
          break;
        case 'Isolamento por Aerosol':
          result.push({ display: "isolamento-aereo", description: data.tipoIsolamento });
          break;
        case 'Isolamento por Gotícula':
          result.push({ display: "isolamento-aereo", description: data.tipoIsolamento }); // TODO: MUDAR DEPOIS PARA ISOLAMENTO COM GOTICULA, ÍCONE NÃO EXISTE AINDA
        break;
        default:
          break;
      }
    }
    if (data.hipodermoclise) result.push({ display: "hipodermoclise", description: "Hipodermóclise"})
    return result;
  }

  getIconsFromFisio(data: any): any {
    const result = [];
    if (data.usoOxigenio) result.push({ display: "oxigenio", description: "Uso de Oxigênio" });

    const everyMovementDescription = data.todasAsDependenciasRIAE ? `<li>RIAE: ${data.todasAsDependenciasRIAE}` : ""
    const floorMovementDescription = data.andarmoradia ? "<li>" + `ANDAR: ${data.andarmoradia}` + "</li>" : ""
    const roomMovementDescription = data.dentroquarto ? "<li>" + `QUARTO: ${data.dentroquarto}` + "</li>" : ""
    const completeMovementDescription = everyMovementDescription + floorMovementDescription + roomMovementDescription;
    const description = '<p style="padding: 10px">Mobilidade</p><br><ul>' + `${completeMovementDescription ? completeMovementDescription : "<li>Independente</li>"}` + "</ul>";
    let display = '';
    if (data.todasAsDependenciasRIAE) {
      switch (data.todasAsDependenciasRIAE) {
        case 'Cadeira de rodas':
          display = "cadeira-rodas";
          break;
        case 'Deambulação com supervisão':
          display = "auxilio";
          break;
        case 'Deambulação com auxílio de 1 pessoa':
          display = "auxilio";
        break;
        case 'Deambulação com auxílio de 2 pessoas':
          display = "auxílios";
        break;
        default:
          display = "independente";
          break;
      }
      const finalMovementObject = { display, description }
      result.push(finalMovementObject);
    }

    if (data.dispositivomarcha) {
      switch (data.dispositivomarcha) {
        case 'Bengala':
          result.push({ display: "bengala", description: "Uso de Bengala" });
          break;
        case 'Andador 2 rodas':
          result.push({ display: "andador-02-rodas", description: "Uso de Andador com 2 Rodas" });
          break;
        case 'Andador 3 rodas':
          result.push({ display: "andador-03-rodas", description: "Uso de Andador com 3 Rodas" });
          break;
        case 'Andador 4 rodas':
          result.push({ display: "andador-04-rodas", description: "Uso de Andador com 4 Rodas" });
        break;
        default:
          break;
      }
    }

    if (data.todosOsMomentos) {
      if (data.todosOsMomentos == 'Não se Aplica') {
        result.push({ display: "exclamacao", description: data.todosOsMomentos });
      } else {
        const alwaysTransferDescription = data.auxilioDispositivo ? `<li>SEMPRE: ${data.auxilioDispositivo}</li>` : ""
        const bedTransferDescription = data.auxilioDispositivo2 ? "<li>" + `LEITO: ${data.auxilioDispositivo2}` + "</li>" : ""
        const chairTransferDescription = data.auxilioDispositivo3 ? "<li>" + `CADEIRA: ${data.auxilioDispositivo3}` + "</li>" : ""
        const completeTransferDescription = alwaysTransferDescription + bedTransferDescription + chairTransferDescription;
        const description = '<p style="padding: 10px">Transferência</p><br><ul>' + `${completeTransferDescription ? completeTransferDescription : "<li>Independente</li>"}` + "</ul>";
        let display = '';
        switch (data.auxilioDispositivo) {
          case 'Auxílio de 1 pessoa':
            display = "auxilio";
            break;
          case 'Auxílio de 2 pessoas':
            display = "auxilios";
            break;
          case 'Maxi Move':
            display = "maximove";
            break;
          case 'Sara Stedy':
            display = "sarastedy";
          break;
          case 'Pivô com auxílio de 1 pessoa':
            display = "auxilio";
          break;
          case 'Pivô com auxílio de 2 pessoas':
            display = "auxilios";
          break;
          default:
            display = "independente";
            break;
        }
        const finalTransferIconObj = { display , description };
        result.push(finalTransferIconObj);
      }
    }

    return result;
  }


  loadDadosGeraisIcons() : void {

    try {

      const idosoBemCuidado$ = this.cadTenantCnf.getCadTenantConfig().pipe( // retorna id(ano) do idoso bem cuidado
        mergeMap(({ AtividadeNo }) => this.modelComponent.getDados(AtividadeNo))); // a partir do ano do idoso bem cuidado gera o ano de todos os cad

      const cadPatientIcons$ = idosoBemCuidado$.pipe(
        filter(dados => dados.Rotulo === 'Cad_Paciente'),
        map(cadastro => cadastro.AtividadeCadastroNo),
        mergeMap(ano => this.selectedPatientId$
          .pipe(
            mergeMap((selectedPatientId) => this.patientCustomSrv.getByCodigoPaciente(ano, ""+selectedPatientId)),
            map((patient) => this.getIconsFromPatient(patient)),
            catchError((err, obs) => {
              console.log(err);
              return obs;
            })
          )
        ),
      )

      const cadNutriIcons$ = idosoBemCuidado$.pipe(
        filter(dados => dados.Rotulo === 'Cad_Avalicao_Nutricional'), // todo: update cad name
        map(cadastro => cadastro.AtividadeCadastroNo),
        mergeMap(ano => this.selectedPatientId$
          .pipe(
            mergeMap((selectedPatientId) => this.avaliacaoNutriCustomSrv.getByIdAndPeriod(ano, selectedPatientId as any as string)),
            map((evolution) => evolution ? this.getIconsFromNutri(evolution) : []), // this here return some icons that will be sent as input to ui component
            catchError((err, obs) => {
              console.log(err);
              return obs;
            })
          )
        ),
      )

      const cadFonoIcons$ = idosoBemCuidado$.pipe(
        filter(dados => dados.Rotulo === 'Cad_Evolucao_Fonoaudiologia'), // todo: update cad name
        map(cadastro => cadastro.AtividadeCadastroNo),
        mergeMap(ano => this.selectedPatientId$
          .pipe(
            mergeMap((selectedPatientId) => this.evolucaoFonoCustomSrv.getByIdAndPeriod(ano, selectedPatientId as any as string)),
            map((evolution) => evolution ? this.getIconsFromFono(evolution) : []),
            catchError((err, obs) => {
              console.log(err);
              return obs;
            })
          )
        ),
      )

      const cadEnfIcons$ = idosoBemCuidado$.pipe(
        filter(dados => dados.Rotulo === 'Cad_Evolucao_Enfermagem'), //todo: update here
        map(cadastro => cadastro.AtividadeCadastroNo),
        mergeMap(ano => this.selectedPatientId$
          .pipe(
            mergeMap((selectedPatientId) => this.evolucaoEnfCustomSrv.getByIdAndPeriod(ano, ""+selectedPatientId)),
            map((patient) => patient ? this.getIconsFromEnf(patient) : []), // this here return some icons that will be sent as input to ui component
            catchError((err, obs) => {
              console.log(err);
              return obs;
            })
          )
        ),
      )

      const cadFisioIcons$ = idosoBemCuidado$.pipe(
        filter(dados => dados.Rotulo === 'Cad_Evolucao_Fisioterapia'), //todo: update here
        map(cadastro => cadastro.AtividadeCadastroNo),
        mergeMap(ano => this.selectedPatientId$
          .pipe(
            mergeMap((selectedPatientId) => this.evolucaoFisioCustomSrv.getByIdAndPeriod(ano, ""+selectedPatientId)),
            map((patient) => patient ? this.getIconsFromFisio(patient) : []), // this here return some icons that will be sent as input to ui component
            catchError((err, obs) => {
              console.log(err);
              return obs;
            })
          )
        ),
      )

      this.patientIcons$ = zip(cadPatientIcons$, cadEnfIcons$, cadFisioIcons$, cadNutriIcons$, cadFonoIcons$).pipe( // esse aqui é enviado como input para o componente que vai renderizar os ícones
        map(([patientIconsDisplay, EnfIconsDisplay, FisioIconsDisplay, nutriIconsDisplay, fonoIconsDisplay]) => [...patientIconsDisplay, ...EnfIconsDisplay, ...FisioIconsDisplay, ...fonoIconsDisplay, ...nutriIconsDisplay]),
      )



    } catch (error: any) {
       this.log.Registrar(this.constructor.name, 'loadDadosGeraisIcons', error.message);
    }
  }
}
