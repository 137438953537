<div class="buttons no-print">
  <app-btn-back style="flex: 2" (backButtonClicked)="onBack()"></app-btn-back>

  <div *ngIf="!isLoading && dataEmergency" [class.isLoading]="isLoading">
    <button mat-raised-button (click)="onPrintClick($event)">
      <mat-icon>print</mat-icon>
      Imprimir
    </button>
  </div>
</div>

<ui-loading [isLoading]="isLoading"></ui-loading>

<h2 *ngIf="!isLoading && !dataEmergency" class="noData">
  Não foi possível carregar os dados
</h2>

<div
  *ngIf="!isLoading && dataEmergency"
  [class.isLoading]="isLoading"
  class="
    emergency-data-content
    wrapper wrapper-content
    animated
    fadeInRight
    scroll
  "
>
  <div class="header">
    <h2>RESUMO PACIENTE</h2>
    <div class="text-center">
      <img
        alt="image"
        class="img-circle"
        width="80"
        src="{{ dataEmergency?.foto }}"
      />
      <h2 class="m-b-xs">
        <strong>{{ dataEmergency.nomePaciente }}</strong>
      </h2>
      <div class="font-bold">{{ dataEmergency.idade }} anos</div>
    </div>
  </div>
  <div class="grid-flex">
    <div class="box-emergency-content emergency">
      <table>
        <tr>
          <th>Plano de Saúde</th>
          <th>Médico Responsável</th>
          <th>Telefone</th>
          <th>Alergia</th>
          <th>Deficiência</th>
        </tr>
        <tr>
          <td>{{ dataEmergency.planosaude }}</td>
          <td>{{ dataEmergency.medicoresponsavel }}</td>
          <td>{{ dataEmergency.telefone }}</td>
          <td>{{ dataEmergency.alergia }}</td>
          <td>{{ dataEmergency.deficiencia }}</td>
        </tr>
      </table>
    </div>

    <div class="box-emergency-content family">
      <h2>CONTATO FAMÍLIA OU RESPONSÁVEL</h2>
      <table>
        <tr>
          <th>Nome</th>
          <th>Parentesco</th>
          <th>Telefone</th>
          <th>Celular</th>
        </tr>
        <tbody
          *ngIf="
            dataEmergency.familiares && dataEmergency.familiares.length > 0
          "
        >
          <tr *ngFor="let responsavel of dataEmergency.familiares" class="box">
            <td>{{ responsavel.nome }}</td>
            <td>{{ responsavel.parentesco }}</td>
            <td>{{ responsavel.telefoneFixo }}</td>
            <td>{{ responsavel.celular }}</td>
          </tr>
        </tbody>
        <tfoot
          *ngIf="
            !dataEmergency.familiares || dataEmergency.familiares.length <= 0
          "
        >
          Nenhum familiar cadastrado!
        </tfoot>
      </table>
    </div>

    <div class="box-emergency-content medication">
      <h2>MEDICAMENTOS</h2>
      <table>
        <tr>
          <th>Qnt</th>
          <th>Nome</th>
          <th>Apresentação</th>
          <th>Dosagem</th>
          <th>Posologia</th>
        </tr>
        <tbody
          *ngIf="
            dataEmergency.medicamentos && dataEmergency.medicamentos.length > 0
          "
        >
          <tr *ngFor="let medication of dataEmergency.medicamentos" class="box">
            <td>{{ medication.quantUtilizadaD }}</td>
            <td>{{ medication.medicamento }}</td>
            <td>{{ medication.apresentacao }}</td>
            <td>{{ medication.dosagem }}</td>
            <td>{{ medication.posologia }}</td>
          </tr>
        </tbody>
        <tfoot
          *ngIf="
            !dataEmergency.medicamentos ||
            dataEmergency.medicamentos.length <= 0
          "
        >
          Nenhum medicamento cadastrado!
        </tfoot>
      </table>
    </div>

    <div class="box-emergency-content vital-signs">
      <h2>SINAIS VITAIS</h2>
      <table>
        <tr>
          <th>Data Avaliação</th>
          <th>Freq. Cardíaca</th>
          <th>Temperatura</th>
          <th>PA Diastólica (Ref 80)</th>
          <th>PA Sistólica (Ref 120)</th>
          <th>Freq. Respiratória - ICP</th>
          <th>Glicemia capilar - Jejum</th>
          <th>Pós Prandial</th>
          <th>Glicose</th>
          <th>Saturação de Oxigênio</th>
          <th>Observação</th>
        </tr>
        <tbody
          *ngIf="
            dataEmergency.sinaisvitais && dataEmergency.sinaisvitais.length > 0
          "
        >
          <tr
            *ngFor="let vitalsign of dataEmergency.sinaisvitais; let i = index"
            class="box"
          >
            <td *ngIf="i < 10">
              {{ vitalsign.dataavaliacao | date: 'dd/MM/yyyy HH:mm' }}
            </td>
            <td *ngIf="i < 10">{{ vitalsign.frequenciaCardiacaBpm }}</td>
            <td *ngIf="i < 10">{{ vitalsign.temperaturaTax }}</td>
            <td *ngIf="i < 10">{{ vitalsign.pADiastolicaRef80 }}</td>
            <td *ngIf="i < 10">{{ vitalsign.pASistolicaRef120 }}</td>
            <td *ngIf="i < 10">{{ vitalsign.frequenciaRespiratoriaICP }}</td>
            <td *ngIf="i < 10">{{ vitalsign.glicemiaCapilarEmJejumMlDl }}</td>
            <td *ngIf="i < 10">{{ vitalsign.posPrandialMlDl }}</td>
            <td *ngIf="i < 10">{{ vitalsign.glicose }}</td>
            <td *ngIf="i < 10">{{ vitalsign.saturacaoOxigenioSO }}</td>
            <td *ngIf="i < 10">{{ vitalsign.laudo }}</td>
          </tr>
        </tbody>
        <tfoot
          *ngIf="
            !dataEmergency.sinaisvitais ||
            dataEmergency.sinaisvitais.length <= 0
          "
        >
          Nenhum sinal vital cadastrado!
        </tfoot>
      </table>
    </div>

    <div class="box-emergency-content evolution">
      <h2>EVOLUÇÕES RECENTES</h2>
      <ng-container *ngIf="dataEmergency?.evolucoes?.length > 0">
        <ng-container
          *ngFor="let evolution of dataEmergency.evolucoes; let i = index"
        >
          <div *ngIf="i < NUM_OF_EVOLUTIONS">
            <span [innerHTML]="evolution.descricaoHtml"></span>
            <br /><br />
          </div>
        </ng-container>
      </ng-container>

      <h3 *ngIf="dataEmergency?.evolucoes?.length <= 0">
        Nenhuma evolução registrada.
      </h3>
    </div>

    <div class="box-emergency-content evolution">
      <h2>ANOTAÇÕES</h2>

      <ng-container *ngIf="dataEmergency?.perfilCliente">
        <h4>Perfil do Cliente:</h4>
        <span>{{ dataEmergency?.perfilCliente }}</span>
      </ng-container>

      <ng-container *ngIf="dataEmergency?.diagnostico">
        <h4>Diagnósticos:</h4>
        <span>{{ dataEmergency?.diagnostico }}</span>
      </ng-container>

      <ng-container *ngIf="dataEmergency?.info">
        <h4>Informações Importantes:</h4>
        <span>{{ dataEmergency?.info }}</span>
      </ng-container>
    </div>

    <div class="logo">
      <img *ngIf="clientLogo" [src]="clientLogo" title="Logo" alt="Logo" />
    </div>

    <div class="box-emergency-content evolution">
      <h5>Emitido em: {{ today | date: 'dd/MM/yyyy HH:mm' }}</h5>
    </div>
  </div>
</div>
