import { Component, OnInit, Input, Output, EventEmitter, Inject, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IMedication } from '@medlogic/shared/shared-interfaces';
import { IPatient } from '@medlogic/shared/shared-interfaces';
import { MaterialDialogService } from '@medlogic/shared/gecore';
import { GlobalService, LogService } from '@medlogic/shared/shared-interfaces';
import { LocalMsgPtBR } from '../../../module/core/service/local-msg-ptBR.service';

@Component({
  selector: 'ml-medication-dialog',
  templateUrl: './medication-dialog.component.html',
  styleUrls: ['./medication-dialog.component.css'],
  encapsulation: ViewEncapsulation.None // permite sobrescrever os estilos default do material dialog.
})
export class MedicationDialogComponent implements OnInit {

  MIN_CHAR_REQUIRED_OBS = 3;

  showTime = true;

  @ViewChild('datePicker', { static: true }) datePicker: any;
  message = '';
  date = new Date();
  maxDateValue = new Date();
  updateStock = true;

  settings = {
    bigBanner: true,
    timePicker: true,
    format: 'dd-MM-yyyy HH:mm',
    defaultOpen: true,
    closeOnSelect: false,
    cal_days_labels: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
    cal_full_days_lables: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
    cal_months_labels: ['Janeiro', 'Fevereiro', 'Março', 'Abril',
      'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro',
      'Outubro', 'Novembro', 'Dezembro'],
    cal_months_labels_short: ['JAN', 'FEV', 'MAR', 'ABR',
      'MAI', 'JUN', 'JUL', 'AGO', 'SET',
      'OUT', 'NOV', 'DEZ'],
  };

  ptBr: any; // define o padrão para o componente de Data/Hora

  @Input() medication = {} as IMedication;
  @Input() patient = {} as IPatient;
  @Output() confirmMedication = new EventEmitter<any>();
  @Output() cancelMedication = new EventEmitter<any>();

  isData = false;
  displayDialog = false; // Define se o diálogo de confirmação de medicação está visível
  isUpdateStockOnApp = false;
  public absoluteValid: boolean = false

  // tslint:disable-next-line: variable-name
  private _observation: string;
  public get observation(): string {
    return this._observation;
  }
  public set observation(v: string) {
    this._observation = v;
  }

  constructor(
    private glb: GlobalService,
    private msg: LocalMsgPtBR,
    protected log: LogService,
    private matDialog: MaterialDialogService,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<MedicationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    // this.title = data.title;
    this.medication = data?.medication;
    this.patient = data?.patient;
    this.isUpdateStockOnApp = data?.isUpdateStockOnApp;
    // Para seleção do valor padrão.
    this.medication.took = true;
  }

  ngOnInit() {
    try {
      this.ptBr = {
        firstDayOfWeek: 0,
        dayNames: this.glb.arrayOfWeekDays,
        dayNamesShort: this.glb.arrayOfWeekDaysAbrev,
        dayNamesMin: this.glb.arrayOfWeekDaysAbrevMin,
        monthNames: this.glb.arrayOfMonthsPtBr,
        monthNamesShort: this.glb.arrayOfMonthsAbrevPtBr
      };
      if(this.medication.status == "S"){
        this.absoluteValid = true
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ngOnInit', error.message);
    }
  }

  /* Click no botão de fechar. */
  onCloseClick(): void {
    try {
      this.dialogRef.close(null);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onYesClick', error.message);
    }
  }

  /* Clique no botão confirmar. */
  onClickItemConfirm(event: any): void {
    try {
      const td = new Date();
      const dtMedication = new Date(this.medication.medicationDate);
      if (this.medication.medicationDate !== undefined) {
        if (this.glb.isFirstDateBiggerThanOrEqualSecondIgnoringTimes(td, dtMedication)) {
          this.dialogRef.close({
            medication: this.medication,
            patient: this.patient,
            observation: this.observation,
            updateStock: this.updateStock
          });
          // this.isData = false;
        } else {
          // this.isData = true;
          this.snackBar.open(this.msg.INVALID_DATE, null, {
            duration: 2000,
            panelClass: 'snackbar'
          });
        }
      } else {
        this.matDialog.openDialogAlert(this.msg.TITLE_SELECT_A_DATE);
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onClickItemConfirm', error.message);
    }
  }

  /* Click no botão cancelar. */
  onClickItemCancel(event: any): void {
    try {
      this.displayDialog = !this.displayDialog;
      this.cancelMedication.emit();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onClickItemCancel', error.message);
    }
  }

  /* Click no botão OK do calendário, para confirmar a data. */
  onClickCalendarOK($event: any): void {
    try {
      this.datePicker.hideOverlay();
      this.datePicker.overlayVisible = false;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onClickItemCancel', error.message);
    }
  }

  /* Formulário pronto para o envio.
   * Observação obrigatório em caso de rejeição,
   * ou de data diferente do dia.
   */
  isValid(): boolean {
    try {
      return this.medicationIsValid() && this.dateIsValid();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'isValid', error.message);
    }
    return true;
  }

  /* Campo de observações está válido. */
  obsIsValid(): boolean {
    try {
      return (!this.observation || (this.observation.length > this.MIN_CHAR_REQUIRED_OBS));
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'obsIsValid', error.message);
    }
    return false;
  }

  /* Campo de observações está válido. */
  medicationIsValid(): boolean {
    try {
      const isValid = this.medication.took || this.obsIsValid();
      return isValid;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'medicationIsValid', error.message);
    }
    return false;
  }

  /* Campo de observações está válido. */
  dateIsValid(): boolean {
    try {
      return this.glb.isEqualIgnoreTime(this.medication.medicationDate, new Date()) || this.obsIsValid();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'dateIsValid', error.message);
    }
    return false;
  }

  /* Clique no botão toggle de Ministrar/Rejeitar. */
  onRejectChange($event: any): void {
    try {
      if (!$event.checked) {
        this.updateStock = false;
        this.medication.status = 'N';
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onRejectChange', error.message);
    }
  }


}
