import {
  Observable,
  catchError,
  defaultIfEmpty,
  filter,
  firstValueFrom,
  lastValueFrom,
  map,
  mergeMap,
  of,
  take,
  tap,
  toArray,
} from 'rxjs';
import {
  IAppMedlogicFhirState,
  FhirActivityDetailService,
  FhirPatientIconsService,
} from '@medlogic/fhir';
import {
  AppLogService,
  EnActivityStatus,
  EnShift,
  IDataComemorativa,
  IEvolucaoFono,
  IInternacoes,
  IMovimentacaoDeLeito,
  IPatient,
  IProfissional,
  IRotina,
  UnsubscribeOnDestroyAdapter,
} from '@medlogic/shared/shared-interfaces';
import { NavigationPwaService } from '../../service/navigation-pwa.service';
import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import {
  CadTenantService,
  ModelComponent,
} from '@medlogic/shared/shared-data-access';
import {
  AvaliacaoNutricionalCustomService,
  DataComemorativaCustomService,
  EvolucaoEnfermagemCustomService,
  EvolucaoFisioterapiaCustomService,
  EvolucaoFonoCustomService,
  InternacoesCustomService,
  MovimentacaoDeLeitoCustomService,
  PatientCustomService,
  ProfissionalCustomService,
  RotinaCustomService,
} from '@medlogic/medlogic/medlogic-data-access';
import { ConfigPwaMedLogicService } from '../../service/config-pwa-medlogic.custom.service';
import { SvgIconService } from '@medlogic/shared/material';

type IDashboardModel = {
  patient: any;
  activities: any[];
};

@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent
  extends UnsubscribeOnDestroyAdapter
  implements OnInit
{
  dashboardActivities$: Observable<IDashboardModel[]> = null;
  loaded: boolean = false;
  floor: Observable<any> = this.route.snapshot.params.key;
  currentTime: Date;
  shift: string;
  dashboardModels: IDashboardModel[] = [];
  baseDashboardModels: IDashboardModel[] = [];
  blocks = ['A', 'B', 'C', 'D'];
  blockIndex = 0;


  aniversariantes = [];
  notasFalecimento = [];
  profissionais: IProfissional[] = [];
  dataComemorativa: IDataComemorativa = null;
  floorDailyActivities: IRotina[] = [];
  lateRondas: any[] = [];

  maxNumberOfPatientsPerScreen = 15;
  numberOfScreens = 1;
  screenNumber = 1;





  patientCadNo: any;
  nutriCadNo: any;
  fonoCadNo: any;
  enfCadNo: any;
  fisioCadNo: any;
  internacaoCadNo: any;
  cadProfissionalNo: any;
  cadRotinaNo: any;
  dataComemorativaNo: any;

  novaAlimentacao: boolean;





  constructor(
    private log: AppLogService,
    private nav: NavigationPwaService,
    private store: Store<IAppMedlogicFhirState>,
    private route: ActivatedRoute,
    private cnf: ConfigPwaMedLogicService,
    private fhirActivityDetailsSrv: FhirActivityDetailService,
    private cadTenantCnf: CadTenantService,
    private modelComponent: ModelComponent,
    private patientCustomSrv: PatientCustomService,
    private evolucaoEnfCustomSrv: EvolucaoEnfermagemCustomService,
    private avaliacaoNutriCustomSrv: AvaliacaoNutricionalCustomService,
    private evolucaoFonoCustomSrv: EvolucaoFonoCustomService,
    private evolucaoFisioCustomSrv: EvolucaoFisioterapiaCustomService,
    private movimentacaoSrv: MovimentacaoDeLeitoCustomService,
    private internacoesCustomSrv: InternacoesCustomService,
    private profissionalCustomSrv: ProfissionalCustomService,
    private rotinaCustomSrv: RotinaCustomService,
    private svgIconSrv: SvgIconService,
    private behaviourSrv: FhirPatientIconsService,
    private dataComemorativaSrv: DataComemorativaCustomService,
  ) {
    super();
  }

  async ngOnInit() {
    this.svgIconSrv.registerIcons();


    const idosoBemCuidado = await firstValueFrom(
      this.cadTenantCnf.getCadTenantConfig().pipe(
        mergeMap(({ AtividadeNo }) =>
          this.modelComponent.getDados(AtividadeNo)
        ),
        toArray()
      )
    );


    this.patientCadNo = idosoBemCuidado.find(
      (i) => i.Rotulo === 'Cad_Paciente'
    ).AtividadeCadastroNo;
    this.nutriCadNo = idosoBemCuidado.find(
      (i) => i.Rotulo === 'Cad_Avalicao_Nutricional'
    ).AtividadeCadastroNo;
    this.fonoCadNo = idosoBemCuidado.find(
      (i) => i.Rotulo === 'Cad_Evolucao_Fonoaudiologia'
    ).AtividadeCadastroNo;
    this.enfCadNo = idosoBemCuidado.find(
      (i) => i.Rotulo === 'Cad_Evolucao_Enfermagem'
    ).AtividadeCadastroNo;
    this.fisioCadNo = idosoBemCuidado.find(
      (i) => i.Rotulo === 'Cad_Evolucao_Fisioterapia'
    ).AtividadeCadastroNo;
    this.internacaoCadNo = idosoBemCuidado.find(
      (i) => i.Rotulo === 'Cad_Internacoes'
    ).AtividadeCadastroNo;
    this.cadProfissionalNo = idosoBemCuidado.find(
      (i) => i.Rotulo === 'Cad_DadosProfissionais'
    ).AtividadeCadastroNo;
    this.cadRotinaNo = idosoBemCuidado.find(
      (i) => i.Rotulo === 'Cad_AtividadesDia'
    ).AtividadeCadastroNo;
    this.dataComemorativaNo = idosoBemCuidado.find(
      (i) => i.Rotulo === 'Cad_DatasComemorativas'
    ).AtividadeCadastroNo;

    this.asyncUpdateDashboard(this.floor);
    this.updateComemorativeDates();
    this.updateFloorDailyActivities();

    // from ten to ten minutes call updateDashboard, concatMap waits for the previous call to finish
    // this.dashboardActivities$ = of(dashboardmodels);

    // this.dashboardActivities$ = timer(0, 600000).pipe(
    //   concatMap(() => this.updateDashboard(this.floor))
    // );




    setInterval(() => {
      this.updateTime();
    }, 1000);

    // change screen every 30 seconds
    setInterval(() => {
      if (this.baseDashboardModels.length > 0) {
        const filteredByBlockDashboardModels = this.baseDashboardModels.filter(
          (m: IDashboardModel) => m?.patient?.bloco === this.blocks[this.blockIndex]
        );

        console.log('filteredByBlockDashboardModels', filteredByBlockDashboardModels);

        this.dashboardModels = filteredByBlockDashboardModels.slice(
          (this.screenNumber - 1) * this.maxNumberOfPatientsPerScreen,
          this.screenNumber * this.maxNumberOfPatientsPerScreen
        );
      this.screenNumber++;
      if (this.screenNumber > this.numberOfScreens) {
        this.screenNumber = 1;
        this.blockIndex++;
        if (this.blockIndex >= this.blocks.length) {
          this.blockIndex = 0;
        }
      }
    }
  }
    , 30000);

    // update dashboard data from 10 to 10 minutes
    setInterval(() => {
      this.asyncUpdateDashboard(this.floor);
    }, 600000);

    // update comemorative date from one to one hour
    setInterval(() => {
      this.updateComemorativeDates();
      this.updateFloorDailyActivities();
    }
    , 3600000);
  }

  status(activity) {
    if (!activity) return null;

    const status = activity.status;

    if (status === EnActivityStatus.done) return 'done dashboard-icon';
    if (status === EnActivityStatus.late) return 'late dashboard-icon';
    if (status === EnActivityStatus.pending) return 'pending dashboard-icon';
    if (status === EnActivityStatus.todo) return 'todo dashboard-icon';
    if (status === EnActivityStatus.extra) return 'extra dashboard-icon';
    if (status === EnActivityStatus.warn) return 'warn dashboard-icon';

    return null; // Return null for unknown status or any default class you prefer.
  }

  statusSpan(activity) {
    if (!activity) return null;

    const status = activity.status;

    if (status === EnActivityStatus.done) return 'done';
    if (status === EnActivityStatus.late) return 'late';
    if (status === EnActivityStatus.pending) return 'pending';
    if (status === EnActivityStatus.todo) return 'todo';
    if (status === EnActivityStatus.extra) return 'extra';
    if (status === EnActivityStatus.warn) return 'warn';

    return null; // Return null for unknown status or any default class you prefer.
  }

  async updateFloorDailyActivities() {

    const floorActivities: any[] = await firstValueFrom(this.rotinaCustomSrv.getByFloor(this.cadRotinaNo, ""+ this.floor));
    const todosActivities: any[] = await firstValueFrom(this.rotinaCustomSrv.getByFloor(this.cadRotinaNo, "Todos"));
    this.floorDailyActivities = [...floorActivities, ...todosActivities]
  }

  async updateComemorativeDates() {
    const datas = await firstValueFrom(this.dataComemorativaSrv.getCadastro(this.dataComemorativaNo));
    if (datas.length == 0 || datas == null) {
      return;
    }
    // separa datas por fixas e móveis
    datas.forEach((d: IDataComemorativa) => {
      const today = new Date();
      if (d.dataFixaAconteceTodosOsAnosNAMESMADATA) {
        if (d.dia == new Date().getDate() && d.mes == new Date().getMonth() + 1) {
        this.dataComemorativa = d;
        }
      } else {
        if (d.dia == new Date().getDate() && d.mes == new Date().getMonth() + 1 && d.ano == new Date().getFullYear()) {
          this.dataComemorativa = d;
        }
      }
    })
  }

  updateTime() {
    const date = new Date();
    this.currentTime = date;

    const hour = this.currentTime.getHours();

    // get shift
    if (hour >= 7 && hour < 13) {
      this.shift = `${EnShift.morning} (07h - 13h)`;
    }
    if (hour >= 13 && hour < 19) {
      this.shift = `${EnShift.afternoon} (13h - 19h)`;
    }
    if (hour >= 19 || hour < 7) {
      this.shift = `${EnShift.night} (19h - 07h)` ;
    }
  }


  // all icons map
  //       00         10          11               12               20                21            22                 23                  24          25         30          31         32               33            40                   41                      42               43
  // [behaviour, diabetico, usoDietaEnteral, dietaDupla, houveMudancaDietaTablet, refeicao, suspenderDietaTablet, espessante, tipoConsistenciaTablet, sigla, riscoFuga, riscoQueda, tipoIsolamento, hipodermoclise, usoOxigenio, todasAsDependenciasRIAE, dispositivoMarcha, auxilioDispositivo]

  async getIconsFromPatient(patient: IPatient) {

    const result = [];
    if (patient?.obito)
      result.push({ display: 'falecimento', description: 'Óbito' });
    else if (patient?.lilas)
      result.push({ display: 'plano-lilas', description: 'Plano Lilás' });
    else if (patient?.situacaoPaciente)
      result.push(await firstValueFrom(this.behaviourSrv.getBehaviourStatus(+patient.prontuario)));
    else result.push({display: 'residente'})

    const today = new Date();

    let admitidoRecentemente = null;
    if (patient?.data?.getFullYear() === today.getFullYear()
      && patient?.data?.getMonth() === today.getMonth()
      && ((patient?.data?.getDate() >= today.getDate()) && (patient?.data?.getDate() <= today.getDate() + 7))) {
        const data = patient.data;
        admitidoRecentemente = { display: 'admissao-residente', dataAdmissao: data };
        result.push(admitidoRecentemente)
      }
    return result;
  }

  //mapa
  // [diabetico, usoDietaEnteral, dietaDupla, houveMudancaDietaTablet]
  getIconsFromNutri(data): any {
    const result = [];
    if (data?.diabetico)
      result.push({ display: 'diabetico', description: 'Diabético' });
    else result.push(null);
    if (data?.usoDietaEnteral)
      result.push({ display: 'dieta-enteral', description: 'Dieta Enteral' });
    else result.push(null);
    if (data?.dietaDupla)
      result.push({ display: 'dieta-dupla', description: 'Dieta Mista' });
    else result.push(null);
    if (data?.houveMudancadieta)
    result.push(true);
    else result.push(null);

    return result;
  }

  // mapa
  // [houveMudancaDietaTablet, refeicao, suspenderDietaTablet, espessante]
  getIconsFromFono(data: IEvolucaoFono): any {
    const result = [];
    if (data?.houveMudancadietaTablet)
      result.push({
        display: 'mudanca-dieta',
        description: 'Mudança de Dieta',
      });
    else result.push(null);
    const consistency = data?.consistencia
      ? `<li>CONSISTÊNCIA: ${data?.consistencia} </li>`
      : '';
    const type = data?.tipo2 ? '<li>' + `TIPO: ${data?.tipo2}` + '</li>' : '';
    const description =
      '<p style="padding: 10px">Alimentação</p><br><ul>' +
      consistency +
      type +
      '</ul>';

    // logica para mostrar apenas um icone de refeição, se não encaixa
    let display = null;
    if (!data?.suspenderDietaTablet && !data?.refeicaoComAdaptacaoTablet) {
      display = 'refeicao';
    } else if (data?.refeicaoComAdaptacaoTablet) {
      display = 'refeicao-adaptada';
    } else if (data?.suspenderDietaTablet) {
      display = 'dieta-suspensa';
    }
    if (display !== null) {
      const finalMealObject = { display, description };
      result.push(finalMealObject);
    } else result.push(null);
    result.push(null); // para não quebrar o programa
    if (data?.usoespessanteTablet) {
      result.push({ display: 'espessante', description: 'Uso de Espessante' });
      switch (data?.tipoConsistenciaTablet) {
        case 'Mel':
          result.push({
            display: 'espessante-mel',
            description: 'Espessante Mel',
          });
          break;
        case 'Pudim':
          result.push({
            display: 'espessante-pudim',
            description: 'Espessante Pudim',
          });
          break;
        case 'Néctar':
          result.push({
            display: 'espessante-nectar',
            description: 'Espessante Néctar',
          });
          break;
        default:
          result.push(null);
          break;
      }
    } else {
      result.push(null); // um pro uso espessante
      result.push(null); // um para tipo consistência
    }

    if (data?.sigla) {
      result.push(data.sigla); // não é ícone, e sim texto
    } else {
      result.push(null);
    }
    return result;
  }

  //mapa
  // [riscoFuga, riscoQueda, tipoIsolamento, hipodermoclise]
  getIconsFromEnf(data: any): any {
    const result = [];
    if (data?.riscoFuga)
      result.push({ display: 'risco-fuga', description: 'Risco de Fuga' });
    else result.push(null);
    if (data?.altoRiscoQueda)
      result.push({ display: 'risco-queda', description: 'Risco de Queda' });
    else result.push(null);
      switch (data?.tipoIsolamento) {
        case 'Isolamento de Contato':
          result.push({
            display: 'isolamento-contato',
            description: data?.tipoIsolamento,
          });
          break;
        case 'Isolamento de Contato e Aerosol':
          result.push({
            display: 'isolamento-contato-aereo',
            description: data?.tipoIsolamento,
          });
          break;
        case 'Isolamento por Aerosol':
          result.push({
            display: 'isolamento-aereo',
            description: data?.tipoIsolamento,
          });
          break;
        case 'Isolamento por Gotícula':
          result.push({
            display: 'isolamento-aereo',
            description: data?.tipoIsolamento,
          }); // TODO: MUDAR DEPOIS PARA ISOLAMENTO COM GOTICULA, ÍCONE NÃO EXISTE AINDA
          break;
        default:
          result.push(null);
          break;
    }
    if (data?.hipodermoclise)
      result.push({ display: 'hipodermoclise', description: 'Hipodermóclise' });
    else result.push(null);
    return result;
  }

  // mapa
  // [usoOxigenio, todasAsDependenciasRIAE, dispositivoMarcha, auxilioDispositivo]
  getIconsFromFisio(data: any): any {
    const result = [];
    if (data?.usoOxigenio)
      result.push({ display: 'oxigenio', description: 'Uso de Oxigênio' });
    else result.push(null);

    const everyMovementDescription = data?.todasAsDependenciasRIAE
      ? `<li>RIAE: ${data?.todasAsDependenciasRIAE}`
      : '';
    const floorMovementDescription = data?.andarmoradia
      ? '<li>' + `ANDAR: ${data?.andarmoradia}` + '</li>'
      : '';
    const roomMovementDescription = data?.dentroquarto
      ? '<li>' + `QUARTO: ${data?.dentroquarto}` + '</li>'
      : '';
    const completeMovementDescription =
      everyMovementDescription +
      floorMovementDescription +
      roomMovementDescription;
    const description =
      '<p style="padding: 10px">Mobilidade</p><br><ul>' +
      `${
        completeMovementDescription
          ? completeMovementDescription
          : '<li>Independente</li>'
      }` +
      '</ul>';
    let display = '';
    if (data?.todasAsDependenciasRIAE) {
      switch (data?.todasAsDependenciasRIAE) {
        case 'Cadeira de rodas':
          display = 'cadeira-rodas';
          break;
        case 'Deambulação com supervisão':
          display = 'auxilio';
          break;
        case 'Deambulação com auxílio de 1 pessoa':
          display = 'auxilio';
          break;
        case 'Deambulação com auxílio de 2 pessoas':
          display = 'auxilios';
          break;
        default:
          display = 'independente';
          break;
      }
      const finalMovementObject = { display, description };
      result.push(finalMovementObject);
    }

    if (data?.dispositivomarcha) {
      switch (data?.dispositivomarcha) {
        case 'Bengala':
          result.push({ display: 'bengala', description: 'Uso de Bengala' });
          break;
        case 'Andador 2 rodas':
          result.push({
            display: 'andador-02-rodas',
            description: 'Uso de Andador com 2 Rodas',
          });
          break;
        case 'Andador 3 rodas':
          result.push({
            display: 'andador-03-rodas',
            description: 'Uso de Andador com 3 Rodas',
          });
          break;
        case 'Andador 4 rodas':
          result.push({
            display: 'andador-04-rodas',
            description: 'Uso de Andador com 4 Rodas',
          });
          break;
        default:
          result.push(null);
          break;
      }
    }

    if (data?.todosOsMomentos) {
      if (data?.todosOsMomentos == 'Não se Aplica') {
        result.push({
          display: 'exclamacao',
          description: data?.todosOsMomentos,
        });
      } else {
        const alwaysTransferDescription = data?.auxilioDispositivo
          ? `<li>SEMPRE: ${data?.auxilioDispositivo}</li>`
          : '';
        const bedTransferDescription = data?.auxilioDispositivo2
          ? '<li>' + `LEITO: ${data?.auxilioDispositivo2}` + '</li>'
          : '';
        const chairTransferDescription = data?.auxilioDispositivo3
          ? '<li>' + `CADEIRA: ${data?.auxilioDispositivo3}` + '</li>'
          : '';
        const completeTransferDescription =
          alwaysTransferDescription +
          bedTransferDescription +
          chairTransferDescription;
        const description =
          '<p style="padding: 10px">Transferência</p><br><ul>' +
          `${
            completeTransferDescription
              ? completeTransferDescription
              : '<li>Independente</li>'
          }` +
          '</ul>';
        let display = '';
        switch (data?.auxilioDispositivo) {
          case 'Auxílio de 1 pessoa':
            display = 'auxilio';
            break;
          case 'Auxílio de 2 pessoas':
            display = 'auxilios';
            break;
          case 'Maxi Move':
            display = 'maximove';
            break;
          case 'Sara Stedy':
            display = 'sarastedy';
            break;
          case 'Pivô com auxílio de 1 pessoa':
            display = 'auxilio';
            break;
          case 'Pivô com auxílio de 2 pessoas':
            display = 'auxilios';
            break;
          default:
            display = 'independente';
            break;
        }
        const finalTransferIconObj = { display, description };
        result.push(finalTransferIconObj);
      }
    }

    return result;
  }

  getIconsFromInternacao(data: IInternacoes): any {
    const result = [];

    const today = new Date();

    let retornoRecente = null;

    if (data?.dataAlta?.getFullYear() === today.getFullYear()
      && data?.dataAlta?.getMonth() === today.getMonth()
      && ((data?.dataAlta?.getDate() >= today.getDate()) && (data?.dataAlta?.getDate() <= today.getDate() + 7))) {
      retornoRecente = { display: 'retorno-hospital', dataAlta: data.dataAlta };
      result.push(retornoRecente);
    }

    return result;
  }


  async asyncUpdateDashboard(floor = this.floor) {
    // load professionals
    this.profissionais = await lastValueFrom(this.profissionalCustomSrv.getCadastro(this.cadProfissionalNo).pipe(
      // verify that today is the birthday
      filter((profissional: IProfissional) => {
        const dataNascimento = profissional?.dataNascimento as Date;
          return (dataNascimento &&
            dataNascimento.getMonth() === new Date().getMonth() &&
            dataNascimento.getDate() === new Date().getDate()
          );
      }),
      toArray(),
    )) as IProfissional[];




    const insertActivitiesIcons = async (prontuario) => {
      const patientIcons = await firstValueFrom(
        this.patientCustomSrv
          .getByCodigoPaciente(this.patientCadNo, '' + prontuario)
          .pipe(
            map((patient) => this.getIconsFromPatient(patient)),
            catchError((err) => {
              console.log(err);
              return of([]); // Return an empty array if an error occurs
            })
          )
      );

      const nutriIcons = await firstValueFrom(
        this.avaliacaoNutriCustomSrv
          .getByIdAndPeriod(this.nutriCadNo, '' + prontuario)
          .pipe(
            map((patient) => this.getIconsFromNutri(patient)),
            catchError((err) => {
              console.log(err);
              return of([]); // Return an empty array if an error occurs
            })
          )
      );

      const fonoIcons = await firstValueFrom(
        this.evolucaoFonoCustomSrv
          .getByIdAndPeriod(this.fonoCadNo, '' + prontuario)
          .pipe(
            map((patient) => this.getIconsFromFono(patient)),
            catchError((err) => {
              console.log(err);
              return of([]); // Return an empty array if an error occurs
            })
          )
      );

      const enfIcons = await firstValueFrom(
        this.evolucaoEnfCustomSrv
          .getByIdAndPeriod(this.enfCadNo, '' + prontuario)
          .pipe(
            map((patient) => this.getIconsFromEnf(patient)),
            catchError((err) => {
              console.log(err);
              return of([]); // Return an empty array if an error occurs
            })
          )
      );

      const fisioIcons = await firstValueFrom(
        this.evolucaoFisioCustomSrv
          .getByIdAndPeriod(this.fisioCadNo, '' + prontuario)
          .pipe(
            map((patient) => this.getIconsFromFisio(patient)),
            catchError((err) => {
              console.log(err);
              return of([]); // Return an empty array if an error occurs
            })
          )
      );

      const internacaoIcons = await firstValueFrom(
        this.internacoesCustomSrv
          .getByIdAndPeriod(this.internacaoCadNo, '' + prontuario)
          .pipe(
            map((patient) => this.getIconsFromInternacao(patient)),
            catchError((err) => {
              console.log(err);
              return of([]); // Return an empty array if an error occurs
            }
          )
      )
    );

      const icons = [patientIcons, nutriIcons, fonoIcons, enfIcons, fisioIcons, internacaoIcons ];
      return icons;
    };

    let patients = await lastValueFrom(
      this.movimentacaoSrv.getByFloor(+floor));




    // resets aniversariantes e notaFalecimento para não duplicar
    this.aniversariantes = [];
    this.notasFalecimento = [];


    let GEPatients = await Promise.all(
      patients
        .filter((p) => p?.prontuarioMEDLOGIC.length > 0)
        .map(async (p) => {
          const patientObservable = this.patientCustomSrv
            .getByCodigoPaciente(this.patientCadNo, '' + p.prontuarioMEDLOGIC)
            .pipe(
              defaultIfEmpty(null), 
              catchError((err) => {
                console.log(err);
                return of(null); 
              })
            );
    
          try {
            const patient = await firstValueFrom(patientObservable);
    
            if (patient !== null) {
              this.getAniversariantes(patient);
              this.getNotaFalecimento(patient);
              return patient;
            } else {
              return null; 
            }
          } catch (error) {
            console.error('Error firstValueFrom:', error);
            return null; 
          }
        })
    );


    patients = GEPatients.
    map(
      (pGE: IPatient) => {
        const patient: IPatient = patients.find((p: IMovimentacaoDeLeito) => p?.prontuarioMEDLOGIC === pGE?.codigo) as IPatient;

        return {
          ...pGE,
          ...patient,
        }
      }
    ).filter( // filtro que vê se paciente está ativo e não está com óbito marcado, ou mostra apenas o de óbito marcado por tres dias
            (patient) => this.filtraObitos(patient));

    // load dayActivities

    const patientActivities = await Promise.all(
      patients.map((p) =>
        firstValueFrom(
          this.fhirActivityDetailsSrv.getDashboardActivitiesById(
            p?.prontuarioMEDLOGIC
          )
        )
      )
    );

    const dashboardModels: IDashboardModel[] = await Promise.all(
      patients.map(async (patient, index) => {
        return {
          patient: {
            ...patient,
            location: `${patient?.leito?.split("-")?.[0]?.slice(2)} ${patient?.bloco} `,
            icons: await insertActivitiesIcons(patient?.prontuarioMEDLOGIC),
            behaviour: await this.behaviourSrv.getBehaviourStatus(patient?.prontuarioMEDLOGIC),
            dayActivity: await this.getDayActivitiesByPatient(patient, this.cadRotinaNo),
          },
          activities: patientActivities[index],
        };
      })
    );

    // sort dashboardmodels by location
    this.blocks = [...new Set(dashboardModels.map((m: IDashboardModel) => m?.patient?.bloco))];


    const filteredByBlockDashboardModels = dashboardModels.filter((d) => d.patient.bloco === this.blocks[this.blockIndex]);

    if (filteredByBlockDashboardModels.length % this.maxNumberOfPatientsPerScreen === 0) {
      this.numberOfScreens = filteredByBlockDashboardModels.length / this.maxNumberOfPatientsPerScreen;
    } else {
      this.numberOfScreens = Math.floor(filteredByBlockDashboardModels.length / this.maxNumberOfPatientsPerScreen) + 1;
    }

    this.screenNumber = 1;

    this.baseDashboardModels = dashboardModels.sort(
      (a, b) => {
        const locationA = a.patient.location
        const locationB = b.patient.location
        return locationA.localeCompare(locationB);
      }
    )

    this.lateRondas = this.baseDashboardModels.filter((d) => this.checkIfRondaIsLate(d.patient.situacaoPaciente, d.activities?.[3]));

    console.log('baseDashboardModels: ', this.baseDashboardModels);
  }

  async getDayActivitiesByPatient(patient, cadRotinaNo) {
    const rotina = await firstValueFrom(this.rotinaCustomSrv.getAll(cadRotinaNo));

    const filteredRotina = rotina.filter((r) => r?.medlogicId === patient.codigo && r.tipoAtividade === 'Individual');

    const mappedRotina = filteredRotina.map((x) => ({
      ...x,
      date: new Date(),
      start: new Date(x.inicio),
      end: new Date(x.fim),
      frequency: x.periodo,
      intervalo: x.aCadaXDiasXaQuantidadediasseremPulados
    }));

    const filteredMappedRotina = mappedRotina.filter((x) =>
      this.rotinaCustomSrv.periodCheck(x.date, x.start, x.end, x.frequency, x.intervalo)
    );

    const transformedRotina = filteredMappedRotina.map((x) => {
      const hourRegex = /([0-2][0-3]:[0-5][0-9])|([0-1][0-9]:[0-9][0-9])|(^[0-9]:[0-9]+)/g;
      const cleanHours = x.horarios.match(hourRegex);
      const possibleDates = cleanHours.map((hour) => {
        const date = new Date(x.date);
        const [hours, minutes] = hour.split(':');
        date.setHours(parseInt(hours));
        date.setMinutes(parseInt(minutes));
        return date;
      });

      const nearestDate = possibleDates.reduce((acc, curr) => {
        const accDiff = Math.abs(acc.getTime() - new Date().getTime());
        const currDiff = Math.abs(curr.getTime() - new Date().getTime());

        return currDiff < accDiff ? curr : acc;
      }, possibleDates[0]);

      return {
        ...x,
        date: nearestDate
      };
    });

    if (transformedRotina.length === 0) {
      return null; // Return null or handle the empty case accordingly
    }

    const finalResult = transformedRotina.reduce((acc, curr) => {
      const accDiff = Math.abs(acc.date.getTime() - new Date().getTime());
      const currDiff = Math.abs(curr.date.getTime() - new Date().getTime());

      return currDiff < accDiff ? curr : acc;
    });

    return finalResult;
  }



  getAge(birthDate: Date): string {
    const today = new Date();
    const age = today.getFullYear() - birthDate.getFullYear();
    const month = today.getMonth() - birthDate.getMonth();

    if (age < 1) return null;

    if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
      return `${age - 1} anos`;
    }

    return `${age} anos`;;
  }

  async getAniversariantes(patient: IPatient) {
    try{
    if (patient.situacaoPaciente && patient.nascimento.getDate() === new Date().getDate() && patient.nascimento.getMonth() === new Date().getMonth()) {
      this.aniversariantes.push(patient);
    }
  } catch (err) {
    this.log.Registrar(this.constructor.name, 'getAniversariantes', err.message);
  }

  }

  async getNotaFalecimento(patient: IPatient) {
    try{
    // checks also if patient.dataObito is in three day range from today
    if (this.filtraObitos(patient) && patient.obito) {
      this.notasFalecimento.push(patient);
    }
  } catch (err) {
    this.log.Registrar(this.constructor.name, 'getNotaFalecimento', err.message);
  }
  }

  filtraObitos(patient) {
    const today = new Date();
    return (!patient?.obito && patient.situacaoPaciente) || (patient?.obito && !patient?.situacaoPaciente && patient?.dataObito?.getFullYear() === today?.getFullYear() && patient?.dataObito?.getMonth() === today?.getMonth() && (patient?.dataObito?.getDate() >= today?.getDate() - 3 || patient?.dataObito?.getDate() <= today?.getDate()));
  }

  checkIfRondaIsLate(situacaoPaciente, ronda) {
    const rondaWasPerformed = ronda?.Progress?.length > 0;
    console.log(situacaoPaciente, ronda, rondaWasPerformed);
    if (!rondaWasPerformed && situacaoPaciente) {
      const scheduledDate = new Date()
      const rondaHour = new Date(ronda?.Scheduled?.ScheduledPeriod?.start).getHours();
      const rondaMin = new Date(ronda?.Scheduled?.ScheduledPeriod?.start).getMinutes();
      scheduledDate.setHours(rondaHour);
      scheduledDate.setMinutes(rondaMin);
      // calculate if ronda was not performed after one hour late to scheduledDate
      const oneHourLate = new Date(scheduledDate.getTime() + 60 * 60 * 1000);
      console.log(rondaHour, rondaMin, new Date() > oneHourLate);
      return new Date() > oneHourLate;
    };
    return false;
  }
}
