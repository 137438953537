  <body><main>
    <header>
      <h1>{{ floor }}&deg; ANDAR - Período {{ shift }}</h1>
      <h1>{{dataComemorativa?.descricao || ""}} {{ currentTime | date: "dd/MM (EEEE) HH:mm" }}</h1>
    </header>
  <!-- <ng-container *ngIf="loaded; else loadingState"> </ng-container> -->
  <ng-container *ngIf="dashboardModels as dashboardRow; else loadingState">
    <div class="main-dashboard">
      <ng-container *ngFor="let row of dashboardRow">
        <table class="table-patient">
            <td class="trinta"><span class="behaviour"><mat-icon class="behaviour-icon" aria-hidden="false" [svgIcon]="row?.patient?.icons[0][0]?.display"></mat-icon></span></td>
            <td><div class="setenta-cinco">{{row.patient.location}}</div></td>
            <td class="trinta"><span class="isolamento"><mat-icon class="dashboard-icon" *ngIf="row?.patient?.icons[3][2]?.display; else empty" aria-hidden="false" svgIcon={{row?.patient?.icons[3][2]?.display}}></mat-icon></span></td>
            <td class="trinta"><span class="deambulacao"><mat-icon class="dashboard-icon" *ngIf="row?.patient?.icons[4][1]?.display; else empty" aria-hidden="false" svgIcon={{row?.patient?.icons[4][1]?.display}}></mat-icon></span></td>
            <td class="trinta"><span class="oxigenio"><mat-icon class="dashboard-icon" *ngIf="row?.patient?.icons[4][0]?.display; else empty" aria-hidden="false" svgIcon={{row?.patient?.icons[4][0]?.display}}></mat-icon></span></td>
            <td class="trinta"><span class="hipordemoclise"><mat-icon class="dashboard-icon" *ngIf="row?.patient?.icons[3][3]?.display; else empty" aria-hidden="false" svgIcon={{row?.patient?.icons[3][3]?.display}}></mat-icon></span></td>
        </table>

          <table class="table-activities">
            <td class="setenta-cinco">
              <span class="mudanca-decubito span-row" *ngIf="row?.activities?.[0] && !row?.activities?.[0]?.Progress; else empty">
                <span><mat-icon [ngClass]="status(row.activities[0])" aria-hidden="false" svgIcon={{row?.activities?.[0]?.icon?.display}}></mat-icon></span>
                <span [ngClass]="statusSpan(row.activities[0])">{{row.activities[0]?.Scheduled?.ScheduledPeriod?.start | date: "HH:mm"}}</span>
              </span>
            </td>
            <td class="setenta-cinco">
                <span class="banho span-row" *ngIf="row?.activities?.[1] && !row?.activities?.[1]?.Progress; else empty">
                  <mat-icon [ngClass]="status(row.activities[1])" aria-hidden="false" svgIcon={{row?.activities[1]?.icon?.display}}></mat-icon>
                  <span [ngClass]="statusSpan(row.activities[1])">{{row.activities[1]?.Scheduled?.ScheduledPeriod?.start | date: "HH:mm"}}</span>
              </span>
            </td>
            <td>
              <span [ngClass]="row?.patient?.icons[2][3] ? 'nova-alimentacao alimentacao span-row' : 'alimentacao span-row'" *ngIf="row?.activities?.[2] && !row?.activities?.[2]?.Progress; else empty">
                  <span class="span-row">
                    <mat-icon [ngClass]="status(row.activities[2])" aria-hidden="false" svgIcon={{row?.patient?.icons[2][1]?.display}}></mat-icon>
                    <span [ngClass]="statusSpan(row.activities[2])">{{row.activities[2]?.Scheduled?.ScheduledPeriod?.start | date: "HH:mm"}}</span>
                  </span>
                  <!-- sigla dieta -->
                  <span [ngClass]="statusSpan(row.activities[2])" class="dieta setenta-cinco"><span *ngIf="row?.patient?.icons[2][5]; else empty" >{{row?.patient?.icons[2][5]}}</span></span>
                  <!-- espessante -->
                  <span class="espessante"><mat-icon class="dashboard-icon" *ngIf="row?.patient?.icons[2][3]?.display; else empty" aria-hidden="false" svgIcon={{row?.patient?.icons[2][3]?.display}}> </mat-icon></span>
                  <!-- consistencia espessante -->
                  <span class="consistencia"><mat-icon class="dashboard-icon" *ngIf="row?.patient?.icons[2][4]?.display; else empty" aria-hidden="false" svgIcon={{row?.patient?.icons[2][4]?.display}}> </mat-icon></span>
                  <!-- diabete -->
                  <span class="diabete"><mat-icon class="dashboard-icon" *ngIf="row?.patient?.icons[1][0]?.display; else empty" aria-hidden="false" svgIcon={{row?.patient?.icons[1][0]?.display}}> </mat-icon></span>
              </span>
            </td>
            </table>
            <table class="table-arrival">
            <td>
              <span class="span-row">
                <span class="admissao">
                  <mat-icon class="dashboard-icon" *ngIf="row?.patient?.icons[0][1]; else empty" aria-hidden="false" svgIcon={{row?.patient?.icons[0][1]?.display}}></mat-icon>
                  {{row?.patient?.icons[0][1]?.dataAdmissao | date}}
                </span>
                <span class="retorno">
                  <mat-icon class="dashboard-icon" *ngIf="row?.patient?.icons[5][0]; else empty" aria-hidden="false" svgIcon={{row?.patient?.icons[5][0]?.display}}></mat-icon>
                  {{row?.patient?.icons[5][0]?.dataAlta | date}}
                </span>
                <span class="day-activity">
                  <mat-icon class="dashboard-icon" *ngIf="row?.patient?.dayActivity; else empty" aria-hidden="false"  svgIcon="tratamento-procedimento"></mat-icon>
                  {{row?.patient?.dayActivity?.date | date: "HH:mm"}} - {{row?.patient?.dayActivity?.nomeAtividade}}
                </span>
              </span>
            </td>
            </table>
            <table class="table-warnings">
            <td>
              <span class="span-row">
                <mat-icon class="fuga dashboard-icon" *ngIf="row?.patient?.icons[3][0]?.display; else empty" aria-hidden="false" svgIcon={{row?.patient?.icons[3][0]?.display}}> </mat-icon>
                <mat-icon class="queda dashboard-icon" *ngIf="row?.patient?.icons[3][1]?.display; else empty" aria-hidden="false" svgIcon={{row?.patient?.icons[3][1]?.display}}> </mat-icon>
              </span>
            </td>
          </table>
        </ng-container>
    </div>
  </ng-container>
</main>
<aside>
  <article class="ronda">
    <section>
      <h2>Ronda <mat-icon class="side-icon" aria-hidden="false" svgIcon="ronda-side"></mat-icon></h2>
      <ul *ngIf="lateRondas.length > 0;" class="ronda-alinha">
        <li *ngFor="let row of lateRondas">
          <mat-icon class="ronda dashboard-icon" aria-hidden="false" svgIcon="ronda"></mat-icon>
          {{row.patient.location}}
        </li>
      </ul>
    </section>
  </article>
  <article class="atividades">
    <section>
      <h2>Atividades do Dia <mat-icon class="side-icon" aria-hidden="false" svgIcon="atividades-do-dia"></mat-icon></h2>
      <ul *ngIf="floorDailyActivities?.length > 0">
        <li *ngFor="let activity of floorDailyActivities">
          <h3>{{activity?.nomeAtividade}}</h3>
          <p>{{activity?.date | date: "HH:mm" }} - {{activity?.local}}</p>
        </li>
      </ul>
    </section>
  </article>

  <article class="nota-falecimento">
    <section>
      <h2>Nota de Falecimento <mat-icon class="side-icon" aria-hidden="false" svgIcon="nota-de-falecimento"></mat-icon></h2>
      <ul *ngIf="notasFalecimento && notasFalecimento?.length > 0">
        <li *ngFor="let patient of notasFalecimento">
          <h3>{{patient?.nomeHospede}}</h3>
          <p>{{getAge(patient?.nascimento)}}</p>
        </li>
      </ul>
    </section>
  </article>

  <article class="aniversariantes">
    <section>
      <h2>Aniversariantes do Dia <mat-icon class="side-icon" aria-hidden="false" svgIcon="aniversario"></mat-icon></h2>
      <ul *ngIf="aniversariantes && aniversariantes?.length > 0">
        <li *ngFor="let patient of aniversariantes">
          <h3>{{patient?.nomeHospede}}</h3>
          <p>Residente - {{getAge(patient?.nascimento)}}</p>
        </li>
      </ul>
      <ul *ngIf="profissionais && profissionais?.length > 0">
        <li *ngFor="let profissional of profissionais">
          <h3>{{profissional?.nomeCompleto}}</h3>
          <p>{{profissional?.cargoFuncao || "Nenhum cargo cadastrado"}}</p>
        </li>
      </ul>
    </section>
  </article>
</aside>
</body>
<ng-template #empty>
  <div class="empty"></div>
</ng-template>

<ng-template #loadingState>
  <div>Carregando...</div>
</ng-template>
