import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { DialogConfirmComponent } from '@medlogic/shared/ui/dialog/ui-dialog-confirm';
import { LogService, IAttachmentHeadersResponse, IFileDeleteRequest, ConfigJsonService } from '@medlogic/shared/shared-interfaces';
import { UnsubscribeOnDestroyAdapter } from '@medlogic/shared/shared-interfaces';
import { FileUploadService } from '../../service/file-upload.service';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { IDocumento } from '@medlogic/shared/shared-interfaces';
import { MatTableDataSource } from '@angular/material/table';
import { of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { error } from '../../service/error';
import { IFileUploadDialog } from '../../interface/IFileUploadDialog';
import { EnFileUploadMode } from '../../enum/EnFileUploadMode';
import { map } from 'rxjs/operators';
import { GlobalService } from '@medlogic/shared/shared-interfaces';

@Component({
  selector: 'lib-file-upload-dialog',
  templateUrl: './file-upload-dialog.component.html',
  styleUrls: ['./file-upload-dialog.component.css']
})
export class FileUploadDialogComponent extends UnsubscribeOnDestroyAdapter implements OnInit {

  dataSource: MatTableDataSource<IDocumento>;
  displayedColumns = ['id', 'documento', 'tipo', 'tamanho', 'versao', 'acoes'];
  singleFileTypes = [EnFileUploadMode.single, EnFileUploadMode.document];
  fileToUpload: File = null;
  progress = 0;
  isLoading = false;
  fileUploadMode = EnFileUploadMode.list;
  selectedDocument: IDocumento;

  ENFILEUPLOADMODE = EnFileUploadMode;

  get isEmpty(): boolean {
    return this.data?.url?.toUpperCase() === 'FOTO' || this.glb.isNullOrEmpty(this.data?.url);
  }

  constructor(
    private fileUploadSrv: FileUploadService,
    private log: LogService,
    private glb: GlobalService,
    private cnfJson: ConfigJsonService,
    private matDialog: MatDialog,
    public dialogRef: MatDialogRef<FileUploadDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IFileUploadDialog) {
    super();
    if (data?.enFileUploadMode) {
      this.fileUploadMode = data?.enFileUploadMode;
    }
  }

  ngOnInit() {
    try {
      this.subs.sink = this.refresh(this.data, this.isEmpty).subscribe();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ngOnInit', error.message);
    }
  }

  private refresh(data: IFileUploadDialog, isEmpty: boolean): Observable<any> {
    try {
      if (data?.ono) {
        this.progress = 0;
        this.fileToUpload = null;
        const fillDataSource = () => tap((docs: IDocumento[]) => {
          if (docs) {
            this.dataSource = new MatTableDataSource<IDocumento>(docs);
          } else {
            this.dataSource = null;
          }
        });
        const filterByVno$ = (vno: number) => map((docHeaders: IAttachmentHeadersResponse) => {
          return docHeaders.docs?.filter((doc: IDocumento) => !vno || !this.isSingleFileType() ? true : this.extractVno(doc) === vno);
        });
        const selectIfSingle = (parData: IFileUploadDialog, parIsEmpty: boolean) => tap((docs: IDocumento[]) => {
          if (!isEmpty && parData?.url && parData?.enFileUploadMode === EnFileUploadMode.single && docs?.length > 0) {
            this.selectedDocument = docs.find(f => f.CaminhoFisico === parData?.url.replace(/\%3A/g, ':'));
          }
        });
        // pipe principal
        return this.fileUploadSrv
          .getDocumentHeaders(data?.ono, data?.tno)
          .pipe(
            filterByVno$(data?.vno),
            fillDataSource(),
            selectIfSingle(data, isEmpty),
            error()
          );
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'refresh', error.message);
    }
    return of(null);
  }

  private extractVno(data: IDocumento): number {
    try {
      // filename pattern: `f-${ano}--${ono}--${uno}--${vno}--${filename}`
      const regex = /f-\d*--\d*--\d*--(\d*)--.*/g;
      const match = regex.exec(data.DocumentoNome);
      const vno: any = match[1];
      return !vno || isNaN(vno) ? null : parseInt(vno, 10);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'extractVno', error.message);
    }
    return null;
  }

  extractIconName(data: IDocumento): string {
    try {
      switch (data.Extensao.toLowerCase()) {
        case '.jpg':
        case '.png':
        case '.gif':
        case '.jpeg':
        case '.bmp':
        case '.tif':
          return 'panorama';
        case '.doc':
        case '.docx':
          return 'description';
        case '.xls':
        case '.xlsx':
          return 'table_rows';
        case '.ppt':
        case '.pptx':
          return 'perm_media';
        case '.pdf':
          return 'picture_as_pdf';
        default:
          return 'insert_drive_file';
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'extractFileName', error.message);
    }
    return null;
  }

  /* Click em fechar. */
  onCloseClick(event?: any): void {
    try {
      if (!this.dataSource || this.dataSource?.data?.length <= 0) {
        // Irá zerar o valor do documento
        this.dialogRef.close({ CaminhoFisico: '' } as IDocumento);
      } else {
        this.dialogRef.close(false);
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onCloseClick', error.message);
    }
  }

  onDownload(doc: IDocumento): void {
    try {
      const a = document?.createElement('a');
      a.style.display = 'none';
      a.href = doc?.CaminhoFisico;
      a.download = doc?.DocumentoNomeExibicao;
      document?.body?.appendChild(a);
      a.click();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onDownload', error.message);
    }
  }

  onDelete(doc: IDocumento): void {
    try {
      const width = '60vw';
      const height = '60vh';
      const title = `Tem certeza que deseja apagar o documento: ${doc?.DocumentoNomeExibicao}`;
      const dialog = this.matDialog
        .open(DialogConfirmComponent,
          { maxWidth: '560px', minWidth: '480px', maxHeight: '240px', width, height, data: { title } });
      this.subs.sink = dialog.afterClosed()
        .pipe(
          this.deleteDocAndRefresh$(doc),
          error()
        ).subscribe();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onDelete', error.message);
    }
  }

  onPreview(doc: IDocumento): void {
    try {
      this.selectedDocument = doc;
      this.fileUploadMode = EnFileUploadMode.preview;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onPreview', error.message);
    }
  }

  canPreview(doc: IDocumento): boolean {
    try {
      if (!doc || this.glb.IsNullOrEmpty(doc?.Extensao)) {
        return false;
      }
      const valid = ['jpg', 'jpeg', 'png', 'gif'];
      return valid.includes(doc?.Extensao?.toLowerCase().replace('.', ''));
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'canPreview', error.message);
    }
  }

  onPreviewBack($event: any): void {
    try {
      this.selectedDocument = null;
      this.fileUploadMode = EnFileUploadMode.list;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onPreviewBack', error.message);
    }
  }

  private deleteDocAndRefresh$ = (doc: IDocumento) => mergeMap((wasConfirmed: boolean) => {
    if (wasConfirmed) {
      return this.fileUploadSrv
        .deleteDocument({ dno: doc?.DocumentoNo, filename: doc.DocumentoNome, container_tag: this.cnfJson.project } as IFileDeleteRequest)
        .pipe(
          mergeMap(() => this.refresh(this.data, this.isEmpty)),
          error()
        );
    }
    return of(null);
  })

  /* It is called after the file be uploaded to the server and container. */
  afterFileUpload(doc: any): void {
    try {
      this.subs.sink = this.refresh(this.data, this.isEmpty).subscribe();
      if (this.isSingleFileType()) {
        this.dialogRef.close(doc);
      }
      localStorage.setItem("imgUpload", doc.Url)
      this.fileUploadMode = this.data?.enFileUploadMode;
      window.dispatchEvent(new Event('eventImgReturn'))
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'afterFileUpload', error.message);
    }
  }

  private isSingleFileType(): boolean {
    try {
      return this.singleFileTypes.includes(this.data?.enFileUploadMode);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'isSingleFileType', error.message);
    }
    return false;
  }

  // showList(): boolean {
  //   try {
  //     return (this.fileUploadMode === EnFileUploadMode.preview) || (this.data?.enFileUploadMode !== EnFileUploadMode.list && !this.isSingleFileType());
  //   } catch (error) {
  //     this.log.Registrar(this.constructor.name, 'showList', error.message);
  //   }
  //   return false;
  // }

  afterOpenWebcam($event: any): void {
    try {
      // this.fileUploadMode = EnFileUploadMode.webcam;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'afterOpenWebcam', error.message);
    }
  }


}
