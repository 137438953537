import { fadeAnimation } from '@medlogic/shared/utils';
import {
  loadVitalSignsBySelectedPatient, selectSearch,
  setSearch, setVitalSign
} from '@medlogic/medlogic/medlogic-state';
import { IAppMedlogicState } from '@medlogic/medlogic/medlogic-shared-interfaces';
import { selectVitalSignsAsSmartList } from '@medlogic/medlogic/medlogic-state';
import {
  ISmartList, GlobalService, EnTheme, UnsubscribeOnDestroyAdapter,
  IListItem, IVitalSign, AppLogService
} from '@medlogic/shared/shared-interfaces';
import { Component, OnInit } from '@angular/core';
import { Observable, from, map, switchMap, tap } from 'rxjs';
import { Store } from '@ngrx/store';
import { FormControl } from '@angular/forms';
import { NavigationPwaService } from '../../service/navigation-pwa.service';

@Component({
  selector: 'ml-vital-sign-list',
  templateUrl: './vital-sign-list.component.html',
  styleUrls: ['./vital-sign-list.component.css'],
  animations: [fadeAnimation]
})
export class VitalSignListComponent extends UnsubscribeOnDestroyAdapter implements OnInit {

  NUMBER_OF_DAYS = 90;
  groups$: Observable<ISmartList[] | any> = this.store
    .select(selectVitalSignsAsSmartList)
    .pipe(
      tap((data) => data.map(data => data?.items.sort((a, b) => b?.identification2 - a?.identification2)))
    )

  enTheme = EnTheme.default;
  readonly PAGE_TITLE = 'Sinais Vitais';
  readonly NUM_MIN_OF_CHAR_SEARCH = 3;
  imgUrl = '/assets/img/vitalsigns.jpg';
  isLoading = true;
  search$: Observable<string>;
  search = new FormControl(null);
  readOnly = false;

  constructor(
    private store: Store<IAppMedlogicState>,
    private log: AppLogService,
    private glb: GlobalService,
    private nav: NavigationPwaService
  ) {
    super();
  }

  ngOnInit() {
    try {
      this.subs.sink = this.store.select(state => selectSearch(state))
        .subscribe(value => this.search.setValue(value));
      this.search.valueChanges.subscribe(search => this.store.dispatch(setSearch({ search })));
      const td = new Date();
      const dtStart = this.glb.addDays(td, -this.NUMBER_OF_DAYS);
      const dtEnd = this.glb.addDays(td, +1);
      this.store.dispatch(loadVitalSignsBySelectedPatient({ dtStart, dtEnd }));
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ngOnInit', error.message);
    }
  }

  onEsc($event: any): void {
    try {
      this.search.setValue('');
      this.store.dispatch(setSearch({ search: '' }));
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onEsc', error.message);
    }
  }

  /* Clique numa pessoa específica. */
  itemClick(
    item: IListItem<IVitalSign>,
    enTheme: EnTheme,
    readOnly: boolean
  ): void {
    try {
      if (item) {
        this.store.dispatch(setVitalSign({ selectedId: item?.id }));
        this.nav.gotoVitalSign(item?.id);
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'itemClick', error.message);
    }
  }

  /* Botão adicionar. */
  addClick($event: any): void {
    try {
      this.store.dispatch(setVitalSign({ selectedId: null }));
      this.nav.gotoVitalSign(-1);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'addClick', error.message);
    }
  }

  /* Click no botão voltar dessa tela. */
  onBack($event: any): void {
    try {
      this.nav.gotoCardPatient();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onBack', error.message);
    }
  }


}
