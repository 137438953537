import { IAlertMessage } from './../../../shared/interface/ialert-message';
import { EnBubbleEvent } from '../../../shared/enum/en-bubble-event.enum';
import { IAtividade } from '../../../shared/interface/iatividade';
import { LibService } from '../../../shared/service/lib.service';
import { IBubble } from '../../../shared/interface/ibubble';
import { FormGroup } from '@angular/forms';
import { AtividadeComponent } from '../atividade/atividade.component';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { EnActivityType } from '@medlogic/shared/gecore';
import { IBasic, ConfigJsonService, IAtividadeComponenteDAL } from '@medlogic/shared/shared-interfaces';
import { LogService } from '@medlogic/shared/shared-interfaces';
import { MsgPtBR } from '@medlogic/shared/shared-interfaces';
import { GlobalService } from '@medlogic/shared/shared-interfaces';
import { IMessage } from '@medlogic/shared/gecore';
import { EnTheme } from '@medlogic/shared/shared-interfaces';
import { Observable } from 'rxjs';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'lib-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabComponent implements OnInit {

  @Input() componentes: IAtividadeComponenteDAL[];
  @Input() atividade: IAtividade;
  @Input() formGroup: FormGroup;
  @Input() formErrors: any;
  @Input() saved: boolean;
  @Input() canShowSavedMessages: boolean;
  @Input() tabs: IBasic[];
  @Input() tabActivedId: number;
  @Input() isMobile: boolean;
  @Input() isAndroid: boolean;
  @Input() activityType: EnActivityType;
  @Input() saveInList: boolean;
  @Input() addToHistory: boolean;
  @Input() isActionBarVisible = true;
  @Input() tarefaNo: number;
  @ViewChild(AtividadeComponent, { static: true }) viewAtividadeComponent;
  @Input() backAtividadeNo: number;
  @Input() backOcorrenciaNo: number;
  @Input() ocorrenciaNo: number;
  @Input() atividadeNome: string;
  @Input() atividadeNo: number;
  @Input() isLoading = false;
  @Input() enTheme = EnTheme.black;
  @Input() printOnly: string[]; // Se preenchido, somente listará para impressão os documentos com o título especificado
  @Input() isEditMode: boolean;
  @Input() isReadOnly: boolean = false;
  @Input() showBackButton = true;

  tabIndex: number;
  msgs: IMessage[] = [];
  zoomFactor = 1;

  idoso = true;

  /* para permitir o uso da enumeração no html. */
  enActivityType: typeof EnActivityType = EnActivityType;

  wasDeletedAndIsEmpty = false;
  /* Evento para permitir que os filhos, netos, etc, se comuniquem com todos os pais até a AtividadeView */
  @Output() eventBubble: EventEmitter<IBubble> = new EventEmitter<IBubble>();
  onEventBubble($event: IBubble): void {
    switch ($event.bubbleEvent) {
      case EnBubbleEvent.tabChange:
        this.onSelectTab($event.params.tabIndex);
        break;
      case EnBubbleEvent.listDelete:
        this.eventBubble.emit($event);
        this.wasDeletedAndIsEmpty = true;
        break;
      case EnBubbleEvent.validateTabComponents:
        this.validateTabComponents($event.params.tabId, this.componentes, this.formGroup);
        break;
      case EnBubbleEvent.navigateNextBack:
        this.navigateNextBack($event, true, this.componentes, this.formGroup);
        break;
      default:
        this.eventBubble.emit($event);
        break;
    }
  }

  public get isDebug(): boolean {
    return this.cnfJson.isDebug || this.cnfJson.showInvisible;
  }

  public get isDialog(): boolean {
    return this.activityType === 'ListDetail';
  }

  public get showOnlySaveButton(): boolean {
    return this.cnfJson.showOnlySaveButton;
  }

  ENTHEME = EnTheme;

  constructor(
    private log: LogService,
    private lib: LibService,
    protected msg: MsgPtBR,
    private glb: GlobalService,
    private cnfJson: ConfigJsonService
  ) { }

  ngOnInit() {
    try {
      this.zoomFactor = this.getZoom();
      this.setTabsVisibility(this.componentes, this.tabs);

      // TODO: Adicionar identificação do ambiente pelo configJson.
      // HACK: Código temporário para identificar se o ambiente é o idoso ou não para aplicar estilização específica.
      const ambiente = this.cnfJson.replaceImgPath("");
      if (ambiente.includes("app")) {
        this.idoso = false;
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ngOnInit', error.message);
    }
  }

  /* Chamará um método do componente filho no momento que todos os componentes estiverem de fato carregados.
  * No entanto, essa ciencia só existe na classe atividade componente view, que deverá portanto chamar esse método.
  */
  protected onAllComponentsLoaded(): void {
    try {
      if (this.viewAtividadeComponent) {
        this.viewAtividadeComponent.onAllComponentsLoaded();
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onAllComponentsLoaded', error.message);
    }
  }

  /* Evento para ativação da tab */
  onTabClick(tab: IBasic): void {
    try {
      this.tabActivedId = tab?.id;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onTabClick', error.message);
    }
  }

  /* Preenche a propriedade enabled de todas as abas, conforme a presença de pelo menos um controle visível.
   * Se for atividade standalone, as abas somente estarão visíveis conforme o progresso.
   */
  protected setTabsVisibility(componentes: any, tabs: IBasic[]): void {
    try {
      tabs.forEach(
        (f) =>
        (f.enabled =
          this.lib.isTabVisible(componentes, f) && this.isStandAloneAndVisible(f.id, this.activityType))
      );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'setTabsVisibility', error.message);
    }
  }

  /* No stand alone somente serão exibidas abas que já foram preenchidas, ou a atual.
  * Nesse cenário são exibidos botões de avançar e anterior e as abas vão sendo exibidas aos poucos.
  */
  protected isStandAloneAndVisible(tabId: number, activityType: EnActivityType): boolean {
    try {
      return (
        (tabId <= this.tabActivedId && activityType === EnActivityType.StandAlone) ||
        activityType !== EnActivityType.StandAlone
      );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'isStandAloneAndVisible', error.message);
    }
    return false;
  }

  /* Evento chamado quando houver erro e preencherá a propriedade vinculada ao action-bar para exibição da mensagem de erro */
  errorNotify(msg: IMessage): void {
    try {
      if (this.lib.addIfNotExist(this.msgs, msg, 'detail')) {
        this.msgs.push(msg);
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'errorNotify', error.message);
    }
  }

  /* Utilizado pelo AtividadeComponent para notificar uma mudança nos controles */
  changesNotify(ctrl: IAtividadeComponenteDAL): void {
    try {
      if (this.formGroup.status !== 'PENDING' && this.formGroup.dirty) {
        // Se tentar mudar algo enquanto há o processo de check dá o erro Expression has
        // changed after it was checked. Previous value: 'false'. Current value: 'true'.
        const changed = this.componentes.map((m) => {
          // localiza o controle correspondente dentro do observable e atualiza a propriedade isVisible
          if (+m.VariavelNo === +ctrl.VariavelNo) {
            m.IsVisible = ctrl.IsVisible;
          }
          return m;
        });
        this.setTabsVisibility(changed, this.tabs);
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'changesNotify', error.message);
    }
  }

  /* Calcula o zoom conforme a altura pré-definida e a altura da tela do usuário */
  protected getZoom(): number {
    try {
      if (this.isMobile) {
        return 1;
      } else {
        const alturaPadrao = 800; // 1010;//470 + (105 + 70) + 100;//descontados cabeçalho e rodapé e uma margem
        // let proporcaoAlturaPadrao = 855 / alturaPadrao;
        return window.outerHeight / alturaPadrao;
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getZoom', error.message);
    }
  }

  /* Evento chamado quando a tela é redimensionada  */
  onResize(): void {
    try {
      this.zoomFactor = this.getZoom();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onResize', error.message);
    }
  }

  /* Verifica se a aba está ativa. Se for móvel, considerará sempre ativa, pois,
  * exibirá um abaixo do outro, Exceto se for StandAlone (conceito next). */
  isActived(tab: IBasic): boolean {
    try {
      if (this.isMobile && (this.activityType !== EnActivityType.StandAlone)) {
        return true;
      }
      if (this.tabs.length > this.tabActivedId) {
        return +this.tabActivedId === +tab.id;
      } else {
        return tab.id === 0;
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'isActived', error.message);
    }
    return false;
  }

  /* Promove a navegação entre abas.
   * blockNextIfInvalid: se true, somente irá avançar passo caso todos os controles da aba estejam válidos.
  */
  protected navigateNextBack(
    $event: any,
    blockNextIfInvalid: boolean = false,
    components: IAtividadeComponenteDAL[],
    fg: FormGroup
  ): void {
    try {
      const step = $event.params.step;
      if (this.tabActivedId + step < 0 || this.tabActivedId + step >= this.tabs.length) {
        return; // interrompe a execução
      }
      if (step > 0) {
        // Avançar
        if (blockNextIfInvalid) {
          // Impedirá o avanço em caso de campos obrigatórios não preenchidos.
          const isValid = this.validateTabComponents(this.tabActivedId, components, fg);
          if (!isValid) {
            const message = {
              firstButtonLabel: this.msg.BUTTON_OK,
              title: this.msg.REQUIRED_FIELD_NOT_FILLED_TITLE,
              icon: 'fa-alert',
              text: this.msg.REQUIRED_FIELD_NOT_FILLED
            } as IAlertMessage;
            this.eventBubble.emit({
              $event,
              bubbleEvent: EnBubbleEvent.alertDialog,
              params: { message, hasConfirmButton: false }
            } as IBubble);
            return; // Interrompe a execução
          }
        }
      }
      this.tabActivedId += step;
      this.tabs[this.tabActivedId].enabled = true;
      // Reiniciar a rolagem da página.
      window.scrollTo(0, 0);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'navigateNextBack', error.message);
    }
  }

  /* Dispara a validação dos componentes da aba fornecida como parâmetro.
   * Se tabId não for fornecido ou for = -1, validará todos os componentes.
   */
  validateTabComponents(
    tabId: number = -1,
    components: IAtividadeComponenteDAL[],
    fg: FormGroup
  ): boolean {
    try {
      tabId = !this.glb.IsNullOrEmpty(tabId) ? tabId : -1;
      const tabComponents = tabId < 0 ? components : components.filter((f) => f.TabIndex === tabId);
      return tabComponents.reduce((a, b) => {
        const ctrl = fg.get(this.lib.getId(b.VariavelNo));
        return a && !ctrl.invalid; // (ctrl.valid|| ctrl.disabled)
      }, true);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'validateTabComponents', error.message);
    }
  }

  /* Seleciona uma aba. */
  protected onSelectTab(tabIndex: number): void {
    try {
      // Necessário ativar um timer, pois senão pode dar erro de mudança após a checagem no ciclo de carregamento do angular.
      setTimeout(() => {
        if (this.tabs.length > tabIndex && this.tabs[tabIndex].enabled) {
          this.tabActivedId = tabIndex;
        } else {
          this.tabActivedId = 0;
        }
      }, 100);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onSelectTab', error.message);
    }
  }

  // /** Verifica se o tema black deve ser aplicado. */
  // isBlack(): boolean {
  //   try {
  //     return this.enTheme === EnTheme.black;
  //   } catch (error) {
  //     this.log.Registrar(this.constructor.name, 'isBlack', error.message);
  //   }
  //   return false;
  // }
}
