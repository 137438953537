<div class="grid">
  <div class="top">
    <app-btn-back
      style="flex: 2"
      (backButtonClicked)="onBack($event)"
    ></app-btn-back>

    <mat-form-field class="search">
      <input
        type="text"
        matInput
        placeholder="Procurar..."
        [formControl]="search"
        (keydown.esc)="onEsc($event)"
      />
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>
  </div>

  <div class="list" *ngIf="groups$ | async as groups; else loading">
    <ui-smart-list-calendar
      [groups]="groups"
      [search]="search$ | async"
      (itemClick)="itemClick($event, enTheme, readOnly)"
      [imgUrl]="imgUrl"
      [enTheme]="enTheme"
    >
    </ui-smart-list-calendar>
  </div>
</div>

<button mat-fab class="btn-add" aria-label="Novo" (click)="addClick(enTheme)">
  <mat-icon>add</mat-icon>
</button>

<ng-template #loading>
  <ui-loading-spinner></ui-loading-spinner>
  <span>Carregando...</span>
</ng-template>
